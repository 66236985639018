
class PageInformation{

    constructor() {

        $("#FormInfo").submit(function(){
            if($("#FormInfo").validationEngine('validate')){
                var data = $(this).serialize() + '&act=envoi';
                var url  = $(this).attr('action');
                var div  = $(this).empty().html('<p>envoi en cours...</p>');

                $.ajax({
                    type: "POST",
                    data : data,
                    url: url,
                    success: function(retour) {
                        div.empty().append(retour);
                    }
                });
            }
            return false;
        });

    }

}