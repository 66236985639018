class TextAppear {
	constructor() {

		const items = document.querySelectorAll("[data-text-appear]");

		items.forEach(elem => {
			elem.innerHTML = elem.innerHTML.replaceAll('-', '<span>-</span>');
			const innerSplit = new SplitText(elem, {
				type: "lines",
				linesClass: "split-inner"
			});
			const outerSplit = new SplitText(elem, {
				type: "lines",
				linesClass: "split-outer"
			});

			const splitTimeline = gsap.timeline({
				scrollTrigger: {
					trigger: elem,
					start: "top 100%",
					end: "bottom 0%",
				},
				onComplete: () => {
					outerSplit.revert();
					innerSplit.revert();
					elem.innerHTML = elem.innerHTML.replaceAll('<span>-</span>', '-');
				}
			});

			splitTimeline.set(elem, {
				visibility: 'visible'
			});

			splitTimeline.from(innerSplit.lines, {
				duration: 1.5,
				yPercent: 150,
				ease: "power4",
				stagger: 0.15,
				autoAlpha: 0
			});
		});
	}
}
