
class PagePresse{

    constructor() {

        let gallery = document.querySelector('.lg-presse');
        if( !gallery )
            return;

        let photos = gallery.querySelectorAll('.gallery-photo');
        photos.forEach( (photo) => {

            photo.addEventListener('click' , (e) => {
                e.preventDefault();
                let url = photo.href;

                $.ajax({
                    type: "POST",
                    data : "act=gallery",
                    url: url,
                    success: function(retour) {
                        try {
                            let data = JSON.parse(retour);

                            let dynamicGallery = lightGallery(photo, {
                                dynamic: true,
                                dynamicEl : data,
                                speed: 800,
                                licenseKey: '0000-0000-000-0001',
                                thumbnail: false,
                                counter: false,
                                download: false,
                                zoom: true,
                                scale: 1,
                                loop: true,
                                fullScreen: false,
                                autoplay: false,
                                autoplayControls: false,
                                allowMediaOverlap: true,
                                hideScrollbar: true,
                                plugins: [lgZoom]
                            });

                            dynamicGallery.openGallery();
                        } catch (e) {
                            return false;
                        }
                    }
                });
            });

        });

        let audios = gallery.querySelectorAll('.gallery-audio');
        audios.forEach( (audio) => {

            audio.addEventListener('click' , (e) => {
                e.preventDefault();
                let url = audio.href;

                $.ajax({
                    type: "POST",
                    data : "act=gallery",
                    url: url,
                    success: function(retour) {
                        try {
                            let data = JSON.parse(retour);

                            let dynamicGallery = lightGallery(audio, {
                                dynamic: true,
                                dynamicEl : [
                                    {
                                        "iframe" : true,
                                        "src" : url
                                    }
                                ],
                                speed: 800,
                                licenseKey: '0000-0000-000-0001',
                                thumbnail: false,
                                counter: false,
                                download: false,
                                zoom: false,
                                scale: 1,
                                loop: true,
                                fullScreen: false,
                                autoplay: false,
                                autoplayControls: false,
                                allowMediaOverlap: true,
                                hideScrollbar: true
                            });

                            dynamicGallery.openGallery();
                        } catch (e) {
                            return false;
                        }
                    }
                });
            });

        });

    }

}