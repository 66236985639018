class MapMaker{

	constructor() {

	}

	createDynamicMap(element){

		let map = new google.maps.Map(element, {
			zoom: 10,
			clickableIcons: false,
			streetViewControl: false,
			mapTypeControl: false,
			fullscreenControl: false,
			zoomControl: false,
			gestureHandling: "cooperative",
			styles: [
                {
                    "featureType": "administrative",
                    "stylers": [
                        { "saturation": -100 }
                    ]
                },{
                    "featureType": "landscape",
                    "stylers": [
                        { "saturation": -100 }
                    ]
                },{
                    "featureType": "poi",
                    "stylers": [
                        { "saturation": -100 }
                    ]
                },{
                    "featureType": "transit.line",
                    "stylers": [
                        { "hue": "#ff00ff" },
                        { "saturation": -100 }
                    ]
                },{
                    "featureType": "transit.station",
                    "stylers": [
                        { "hue": "#d81769" }
                    ]
                },{
                    "featureType": "water",
                    "stylers": [
                        { "hue": "#ff0077" },
                        { "saturation": -100 },
                        { "lightness": -33 }
                    ]
                },{
                    "featureType": "road",
                    "stylers": [
                        { "hue": "#e500ff" },
                        { "saturation": -86 }
                    ]
                }
            ]
		});

        if( element.dataset.mapZoom ){
            map.setZoom(parseInt(element.dataset.mapZoom));
        }

		map.bounds = new google.maps.LatLngBounds();
		map.markers = [];
		this.zoomControls(element, map);
		return map;

	}

	createStaticMap(element, markers){

		let imgWidth = "640";
		let imgHeight = "640";
		if(element.classList.contains('map-fullwidth')){
			imgHeight = "360";
		}

		let url = "https://maps.googleapis.com/maps/api/staticmap?";
		url += "key=" + mcubeArgs.gmapApiKey;
		url += "&map_id=" + mcubeArgs.gmapMapId;
		url += "&size=" + imgWidth + "x" + imgHeight;
		url += "&scale=2";
		url += "&format=JPEG";

		if( markers.length === 1 ){
			url += "&center=" + markers[0].dataset.markerLat + "," + markers[0].dataset.markerLng;
			url += "&zoom=" + element.dataset.zoom;
		}

		markers.forEach( (marker) => {
			url += "&markers=";
			url += marker.dataset.markerLat + "," + marker.dataset.markerLng;
			if( marker.dataset.pointer ){
				url += "|icon:" + encodeURI(marker.dataset.pointer);
			}
		});

		let image = new Image();
		image.src = url;
		image.width = imgWidth;
		image.height = imgHeight;
		element.appendChild(image);

	}

	zoomControls(element, map) {
		let zoomIn = element.closest('.map-container').querySelector('.map-zoom-in');
		if (zoomIn) {
			zoomIn.addEventListener('click', () => {
				map.setZoom(map.getZoom() + 1);
			});
		}

		let zoomOut = element.closest('.map-container').querySelector('.map-zoom-out');
		if (zoomOut) {
			zoomOut.addEventListener('click', () => {
				map.setZoom(map.getZoom() - 1);
			});
		}
	}

	createMarker(marker, map){
		let m = new google.maps.Marker({
			position: {
				lat: parseFloat(marker.dataset.markerLat),
				lng: parseFloat(marker.dataset.markerLng)
			},
			map: map
		});
		if( marker.dataset.pointer ){
			m.setIcon( this.createIcon(marker.dataset.pointer, marker.dataset.pointerSize, marker.dataset.pointerAnchor) );
		}
		map.markers.push(m);
		map.bounds.extend(m.getPosition());
	}

	createIcon(icon, size, anchor){
		let s = size.split('x');
		let a = anchor.split('x');
		return {
			url: icon,
            scaledSize: new google.maps.Size(s[0], s[1]),
			size: new google.maps.Size(s[0], s[1]),
			origin: new google.maps.Point(0, 0),
			anchor: new google.maps.Point(s[0]/2, s[1])
		};
	}

}