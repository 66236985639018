class ResizeEvents {
	constructor() {

		let timer;
		let orientationchanged = true;
		let newOrientation;
		let oldOrientation = window.innerWidth > window.innerHeight ? "landscape" : "portrait";

		window.addEventListener("resize", () => {
			if (timer) {
				clearTimeout(timer);
			}

			timer = setTimeout(() => {
				if (mobileCheck.isMobile) {
					newOrientation = window.innerWidth > window.innerHeight ? "landscape" : "portrait";
					orientationchanged = newOrientation !== oldOrientation;
					oldOrientation = newOrientation;
					if (!orientationchanged) {
						window.dispatchEvent(new Event("mobileResize"));
					}
				}

				if (orientationchanged) {
					window.dispatchEvent(new Event("customResize"));
				}
			}, 100);
		});

		window.addEventListener("customResize", () => {
			resizeCallbacks.forEach(callback => {
				this.callResizesFunctions(callback);
			});
		});

		window.addEventListener("mobileResize", () => {
			mobileResizeCallbacks.forEach(callback => {
				this.callResizesFunctions(callback);
			});
		});
	}

	callResizesFunctions(callback) {
		if (typeof callback.resize === "function") {
			try {
				callback.resize();
			} catch (error) {
				console.log(error);
			}
		} else {
			console.log("Starterkit : Missing resize function in object " + callback.constructor.name);
		}
	}
}

