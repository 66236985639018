
class PageGallery{

    constructor() {

        let gallery = document.querySelector('.lg-gallery');
        if( !gallery )
            return;

        let msnry = new Masonry( gallery, {
            percentPosition: true,
            itemSelector: '.col-gallery'
        });

        let imgLoad = imagesLoaded( gallery, { background: true } );
        imgLoad.on("done", function (instance) {
        }).on("progress", function (instance, image) {
            msnry.layout();
        });

        lightGallery(gallery, {
            selector: '.zoom',
            speed: 800,
            licenseKey: '0000-0000-000-0001',
            thumbnail: false,
            counter: false,
            download: false,
            zoom: false,
            scale: 1,
            loop: true,
            fullScreen: false,
            autoplay: false,
            autoplayControls: false,
            allowMediaOverlap: true
        });

    }

}