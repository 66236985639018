
class PageTestimony{

    constructor() {

        let gallery = document.querySelector('.testimony-container');
        if( !gallery )
            return;

        new Masonry( gallery, {
            percentPosition: true,
            itemSelector: '.col-testimony'
        });

        // $.raty.path = 'images/raty';
        $('.raty').each(function(){
            $(this).raty({
                starType: 'i',
                starOff: 'icon-heart-o',
                starOn:  'icon-heart',
                width:   '80px',
                scoreName: $(this).attr('name'),
                target: $(this).next('span'),
                hints: ['déplorable', 'pas terrible', 'pas mal', 'très bien', 'impeccable'],
                targetKeep : true
            });
        });

        $('.result').each(function(){
            $(this).raty({
                starType: 'i',
                starOff: 'icon-heart-o',
                starOn:  'icon-heart',
                width:   '80px',
                target: $(this).next('span'),
                hints: ['déplorable', 'pas terrible', 'pas mal', 'très bien', 'impeccable'],
                targetKeep : true,
                readOnly: true,
                score: function() {
                    return $(this).attr('data-score');
                }
            });
        });

        $("#FormTemoignage").submit(function(){
            if($("#FormTemoignage").validationEngine('validate')){
                var data = $(this).serialize() + '&act=envoi';
                var url  = $(this).attr('action');
                var div  = $(this).empty().html('<p>envoi en cours...</p>');

                $.ajax({
                    type: "POST",
                    data : data,
                    url: url,
                    success: function(retour) {
                        div.empty().append(retour);
                    }
                });
            }
            return false;
        });

    }

}