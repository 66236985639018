var redirectTo = function(url){
    window.location.href = url;
};

var eventPanier = function(data){
    // $('.panier--content').toggleClass('loading').spin('loader');
    $('.panier--content').toggleClass('loading');
    $.ajax({
        type: "post",
        url: "/commande/",
        data: data,
        success: function(retour) {
            try {
                var data = JSON.parse(retour);
                redirectTo(data.redirect);
            } catch(ex) {
                alert(retour);
            }
        }
    });
};

$(document).ready(function(){

    /*
     ######     #######   ##     ##  ##     ##     ###     ##    ##   ########   ########
    ##    ##   ##     ##  ###   ###  ###   ###    ## ##    ###   ##   ##     ##  ##
    ##         ##     ##  #### ####  #### ####   ##   ##   ####  ##   ##     ##  ##
    ##         ##     ##  ## ### ##  ## ### ##  ##     ##  ## ## ##   ##     ##  ######
    ##         ##     ##  ##     ##  ##     ##  #########  ##  ####   ##     ##  ##
    ##    ##   ##     ##  ##     ##  ##     ##  ##     ##  ##   ###   ##     ##  ##
     ######     #######   ##     ##  ##     ##  ##     ##  ##    ##   ########   ########
    */

    // lien commande prestation
    $(".js-panier-select").click(function(e){
        e.preventDefault();
        var prestation =  $(this).attr("data-num");
        eventPanier( "act=SINGLE&prestation="+prestation );
    });

    // bouton ajout prestation
    $(".fPrestation").submit(function(){
        var data =  $(this).serialize()+"&act=ADD";
        eventPanier( data );
        return false;
    });

    // suppression d'une ligne dans la commande
    $(".js-panier-remove").unbind('click').click(function(e){
        e.preventDefault();
        eventPanier( "act=REMOVE&id="+$(this).attr('data-num') );
    });

    // bouton passage aux coordonnées
    $(".fSummary").submit(function(e){
        e.preventDefault();
        eventPanier( "act=INFORMATION" );
        return false;
    });

    // affiche / cache le formulaire de cadeau
    $("input[type='radio'][name='~address-gift']").change(function(e){
        e.preventDefault();

        if( $(this).val() > 0 )
            $(".fInformation").find('div.hidden').css("display", "none").slideDown();
        else
            $(".fInformation").find('div.hidden').slideUp();

        // $(".fInformation").find('div.hidden').css('display','none').slideToggle();
        $(".formError").remove();
        // eventPanier( "act=INFORMATION" );
        return false;
    });

    $(".fInformation").validationEngine({
        scroll : false,
        ignore: ""
    });

    $(".fInformation").submit(function(){
        if($(this).validationEngine('validate')){
            var data = $(this).serialize() + '&act=VALID';
            eventPanier( data );
        }
        return false;
    });

    $(".option-title").click(function() {
        $(this).next(".option-detail").slideToggle( "slow" );
    });

    let basket = document.querySelector(".cart-basket-container");
    if( !basket )
        return;

    ScrollTrigger.create({
        trigger: ".page-content",
        pin: basket,
        start: "top top+=" + ( document.querySelector(".nav-main").offsetHeight + document.querySelector(".breadcrumb").offsetHeight ),
        endTrigger: ".footer",
        end: "bottom bottom"
    });


});