class Collapse{

    constructor() {

        let collapses = document.querySelectorAll('.collapse .collapse-title');
        let index = 0;

        collapses.forEach((el) => {

            let parent = el.closest('.collapse');
            let content = el.nextElementSibling;

            if(index === 0){
                parent.classList.add('open');
                content.style.maxHeight = content.scrollHeight + "px";
                setTimeout(() => {
                    ScrollTrigger.refresh();
                }, 501);
            }

            el.addEventListener('click', () => {
                parent.classList.toggle('open', !parent.classList.contains('open'));
                if (parent.classList.contains('open')) {
                    content.style.maxHeight = content.scrollHeight + "px";
                } else {
                    content.style.maxHeight = null;
                }

                // Attente de l'animation CSS de 0.5s
                setTimeout(() => {
                    ScrollTrigger.refresh();
                }, 501);
            });

            index++;
        });

    }

}