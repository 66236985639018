
class AppearBlock{

    constructor() {

        let blocksAppear = document.querySelectorAll('.js-appear-block');
        blocksAppear.forEach( (block) => {

            gsap.set(block, {
                opacity: 0,
                skewX: -4,
                y : 50
            });

            gsap.to(block, {
                opacity: 1,
                skewX: 0,
                y : 0,
                duration: 1.5,
                ease: "power2.out",
                scrollTrigger: {
                    trigger: block,
                    start: "top 90%"
                }
            });

        });

    }

}