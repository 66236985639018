class CollapseQuestion {
    constructor() {

        let collapses = document.querySelectorAll('#accordion-questionnaire .collapse-head');
        let index = 0;

        collapses.forEach((el) => {

            let parent = el.closest('.collapse');
            let content = el.nextElementSibling;

            el.addEventListener('click', () => {

                parent.classList.toggle('open', !parent.classList.contains('open'));

                if (parent.classList.contains('open')) {
                    content.style.maxHeight = content.scrollHeight + "px";
                } else {
                    content.style.maxHeight = null;
                }

                smoother.scrollTo(parent, true, "top 150px");

                // Attente de l'animation CSS de 0.5s
                setTimeout(() => {
                    ScrollTrigger.refresh();
                }, 501);

            });

            index++;
        });

    }
}
