
class PageNews{

    constructor() {

        let gallery = document.querySelector('.lg-news');
        if( !gallery )
            return;

        lightGallery(gallery, {
            selector: '.news-gallery',
            speed: 800,
            licenseKey: '0000-0000-000-0001',
            thumbnail: false,
            counter: false,
            download: false,
            zoom: false,
            scale: 1,
            loop: true,
            fullScreen: false,
            autoplay: false,
            autoplayControls: false,
            allowMediaOverlap: true
        });

    }

}