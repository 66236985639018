class DataLink {
	constructor() {
		this.items = document.querySelectorAll("[data-link]");
		this.items.forEach(link => {
			link.addEventListener("click", e => {
				this.handleLink(link, e);
			});
			link.addEventListener("auxclick", e => {
				this.handleLink(link, e);
			});
		});

        if (pageTransitionEnabled) {
            this.items = document.querySelectorAll('a:not([data-link]):not([target="_blank"])');
            this.items.forEach(link => {
                link.addEventListener("click", e => {
                    e.preventDefault();
                    pageTransition.leave(() => {
                        window.location = link;
                    });
                });
                link.addEventListener("auxclick", e => {
                    e.preventDefault();
                    pageTransition.leave(() => {
                        window.location = link;
                    });
                });
            });
        }

	}

	handleLink(link, e) {
		e.preventDefault();
		e.stopPropagation();

		const url = link.dataset.link;
		const target = link.dataset.target;
		const a = document.createElement('a');
		a.href = url;

		if (e.ctrlKey || e.shiftKey || e.metaKey || (e.button && e.button === 1)) {
			a.target = '_blank';
		} else if (target) {
			a.target = target;
		}

		if (a.target) {
			a.click();
			a.remove();
		} else {
			if (pageTransitionEnabled) {
				pageTransition.leave(() => {
					this.navigate(a);
				});
			} else {
				this.navigate(a);
			}
		}

		return false;
	}

	navigate(link) {
		document.body.appendChild(link);
		link.click();
		link.remove();
	}
}
