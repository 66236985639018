class Navigation {

    constructor() {

        this.header = document.querySelectorAll('.nav-main');
        Observer.create({
            target: window,         // can be any element (selector text is fine)
            type: "wheel,touch",    // comma-delimited list of what to listen for ("wheel,touch,scroll,pointer")
            tolerance: 30,
            onUp: () => this.showNavigation(),
            onDown: () => this.hideNavigation(),
        });

        let toggleNav = document.querySelectorAll('.custom-toggle');
        toggleNav.forEach((btn) => {
            btn.addEventListener('click', () => {
                document.body.classList.toggle('main-nav-open', !document.body.classList.contains('main-nav-open'));
                smoother.paused(document.body.classList.contains('main-nav-open'));
            });
        });

        let menuLinks = document.querySelectorAll('.header-desktop .menu--left li');
        let menuMedias = document.querySelectorAll('.header-desktop .nav-collapse-medias .media');

        menuLinks.forEach((item) => {

            item.addEventListener('mouseover', (e) => {
                let isActive = document.querySelector('.header-desktop .nav-collapse-medias .media[data-index="' + item.dataset.index + '"]');
                isActive.classList.remove('is-out');
                isActive.classList.add('is-visible');

                if( item.hasAttribute("data-video") ){
                    isActive.querySelector('video').setAttribute("src", item.getAttribute("data-src"));
                }

            });

            item.addEventListener('mouseleave', (e) => {
                menuMedias.forEach((media) => {
                    if (media.classList.contains('is-visible')) {
                        media.classList.add('is-out');
                    }
                });
            });
        });

    }

    hideNavigation() {
        if (smoother.scrollTop() > 250) {
            gsap.to(this.header, {
                yPercent: -100,
                duration: 0.5
            });
        }
    }

    showNavigation() {
        gsap.to(this.header, {
            yPercent: 0,
            duration: 0.5
        });
    }

}