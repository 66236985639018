class PagePrestation{

    constructor() {

        this.listing = document.querySelector('.listing-prestation');
        if( this.listing )
            this.buildListing();

        let header = document.querySelector(".header-desktop .nav-main");
        this.aside = document.querySelector('.aside-prestation');
        if( this.aside && window.innerWidth > 767 ){
            ScrollTrigger.create({
                trigger: this.aside,
                start: `top ${header.offsetHeight}`,
                pin: true,
                endTrigger: ".options-prestation",
                end: "bottom bottom"
            });
        }

    }

    buildListing(){

        let prestation = this.listing.querySelectorAll('li');
        prestation.forEach( (presta) => {
            presta.addEventListener('mouseenter', () => {
                let index = presta.dataset.index;
                let media = document.querySelector(`.listing-prestation-medias .media[data-index="${index}"]`);
                media.classList.remove('active');
                gsap.fromTo(media, {
                    opacity: 0,
                    rotation : -7,
                    xPercent : -5,
                    yPercent : -5,
                    duration: 0,
                    immediateRender: true,
                    overwrite: true
                }, {
                    opacity: 1,
                    rotation : -5,
                    xPercent : -10,
                    yPercent : 0,
                    duration: 0.6,
                    immediateRender: true,
                    overwrite: true
                });
            });

            presta.addEventListener('mouseleave', () => {
                let index = presta.dataset.index;
                let media = document.querySelector(`.listing-prestation-medias .media[data-index="${index}"]`);
                gsap.to(media, {
                    opacity: 0,
                    duration: 0.3,
                    immediateRender: true,
                    overwrite: true
                });
            });

        });

    }

}