class CustomPageTransition {

	constructor() {
		this.entered = false;
		this.pageTransition = document.querySelector(".page-transition");
        this.panes = this.pageTransition.querySelectorAll('.page-transition-pane');
        this.logo = this.pageTransition.querySelectorAll('.page-transition-logo');

        gsap.set(this.panes[1],{
            xPercent : -100
        });

        gsap.set('.page-container',{
            opacity: 1
        });
        gsap.set('.header-desktop',{
            opacity: 1
        });

        this.tl = gsap.timeline({
            paused : true
        });
        this.tl.to(this.panes[1],{
            xPercent : 0,
            duration : 0.8,
            ease: "power3.out",
        }, 0.3);
        this.tl.to(this.panes[1],{
            scaleX : 0,
            duration : 0.8,
            ease: "power3.out",
        }, 0.7);
        this.tl.to(this.panes[0],{
            scaleX : 0,
            duration : 0.8,
            ease: "power3.out",
        }, 0.5);
        this.tl.to(this.logo,{
            // x : -window.innerWidth / 4,
            opacity: 0,
            duration : 0.8,
            ease: "power3.out",
        }, 0.4);
	}

	leave(doneCallback) {
		this.entered = false;
        this.tl.eventCallback('onReverseComplete', () => {
            doneCallback();
        });
        this.tl.eventCallback('onStart', () => {
            this.pageTransition.style.pointerEvents = "initial";
        });
        this.tl.reverse();
	}

	enter(doneCallback) {
		if (this.entered) {
            return;
        }
        this.tl.eventCallback('onComplete', () => {
            this.pageTransition.style.pointerEvents = "none";
            doneCallback();
        });
        this.tl.play();
		this.entered = true;
	}
}
