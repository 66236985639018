class PageHome {

    constructor() {

        let btn = document.querySelector('.slider-btn');
        if (!btn)
            return;

        btn.addEventListener('click', () => {
            smoother.scrollTo(document.querySelector('.slider-content').offsetHeight, true);
        });

        this.beforeAfterElement = document.querySelector(".home-before-after");
        if (this.beforeAfterElement) {
            this.beforeAfter();
        }

        this.imageParallax = document.querySelectorAll('.home-parallax-image');
        if (this.imageParallax && window.innerWidth > 767)
            this.imagesParallax();

        let mainIllustration = document.querySelector(".home-illustration-animation");
        if (mainIllustration) {

            if (window.innerWidth > 767) {
                gsap.to('.home-illustration', {
                    yPercent: -50,
                    opacity: 1,
                    ease: "power2.out",
                    duration: 1.5,
                    scrollTrigger: {
                        trigger: '.home-keys',
                        start: "top bottom",
                    }
                });
            }

            gsap.to(mainIllustration, {
                ease: "none",
                yPercent: -15,
                rotate: "-10deg",
                scrollTrigger: {
                    trigger: mainIllustration,
                    start: "top bottom",
                    end: "bottom top",
                    scrub: 1,
                }
            });

        }
        this.pinLipstick();
    }

    pinLipstick() {
        if (window.innerWidth > 767) {
            const lipstickContainer = document.querySelector('.lipstick-container');
            const homeKeys = document.querySelector('.home-keys');
            gsap.to(lipstickContainer, {
                y: (homeKeys.clientHeight - lipstickContainer.clientHeight) * 0.7,
                ease: "none",
                scrollTrigger: {
                    trigger: ".home-keys",
                    start: "top bottom",
                    end: "bottom top",
                    scrub: 0.3,
                }
            });
        }
    }

    imagesParallax() {

        this.imageParallax.forEach((image) => {
            gsap.to(image.querySelector('img'), {
                scale: 1,
                ease: "none",
                scrollTrigger: {
                    trigger: image,
                    start: "top bottom",
                    end: "center center",
                    scrub: 1
                }
            });

            gsap.to(image.querySelector('.parallax'), {
                yPercent: 20,
                ease: "none",
                scrollTrigger: {
                    trigger: image,
                    start: "center center",
                    end: "bottom top",
                    scrub: 1
                }
            });
        });

    }

    beforeAfter() {

        this.inner = this.beforeAfterElement.querySelector(".inner");
        this.clipped = this.beforeAfterElement.querySelector(".clipped");
        this.dragger = this.beforeAfterElement.querySelector(".dragger");
        this.ratio = 0.25;

        this.draggable = new Draggable(".dragger", {
            type: "x",
            bounds: this.inner,
            onDrag: () => this.onDrag()
        });

        window.addEventListener("resize", () => this.onResize());
        this.onResize();

    }

    onDrag() {
        const width = this.inner.offsetWidth;
        gsap.set(this.clipped, {
            clipPath: `inset(0px ${width - this.draggable.x - this.dragger.offsetWidth / 2}px 0px 0px)`
        });
        this.ratio = this.draggable.x / width;
    }

    onResize() {
        const width = this.inner.offsetWidth;
        const x = (this.ratio * width);

        gsap.set(this.dragger, {
            x: x - this.dragger.offsetWidth / 2
        });

        gsap.set(this.clipped, {
            clipPath: `inset(0px ${width - x}px 0px 0px)`
        });

        this.draggable.update(true);
    }

}
