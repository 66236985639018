
class ButtonFollower{

    constructor() {

        let buttons = document.querySelectorAll('.btn-follower');

        let mm = gsap.matchMedia();

        this.handleMouse = evt => this.handleMouseOver(evt);

        // add a media query. When it matches, the associated function will run
        mm.add("(min-width: 768px)", () => {

            buttons.forEach( (btn) => {
                let container = btn.closest('.btn-follower-container');
                container.addEventListener('mousemove', this.handleMouse);
                container.addEventListener('mouseleave', () => {
                    gsap.to(btn, {
                        opacity: 0,
                        scale: 0
                    });
                    btn.style.pointerEvents = "none";
                });
            });

        });

        mm.add("(max-width: 767px)", () => {
            // custom cleanup code here (runs when it STOPS matching)
            buttons.forEach( (btn) => {
                let container = btn.closest('.btn-follower-container');
                container.removeEventListener('mousemove', this.handleMouse);
                gsap.set(btn, {
                    x: 0,
                    y: 0,
                    opacity: 1,
                    scale: 1
                });
            });
        });

    }

    handleMouseOver(e){

        let container = e.target.closest('.btn-follower-container') || e.target;
        let btn = container.querySelector('.btn-follower');
        let rect = container.getBoundingClientRect();
        let relX = (e.clientX - rect.left) - btn.offsetWidth / 2;
        let relY = (e.clientY - rect.top) - btn.offsetHeight / 2;

        let stopElement = container.querySelector('.btn-follower-container-stop');
        if( stopElement ) {
            let stopElementRect = stopElement.getBoundingClientRect();
            if( e.clientY > stopElementRect.top && e.clientY < stopElementRect.bottom && e.clientX > stopElementRect.left && e.clientY < stopElementRect.right){
                gsap.to(btn, {
                    opacity: 0,
                    scale: 0
                });
                btn.style.pointerEvents = "none";
                return;
            }
        }

        gsap.to(btn, {
            opacity: 1,
            scale: 1
        });
        btn.style.pointerEvents = "unset";

        if( e.clientY < rect.top || e.clientY > rect.bottom ){
            btn.style.pointerEvents = "none";
        } else{
            btn.style.pointerEvents = "unset";
        }

        gsap.to(btn, {
            duration: 0.5,
            ease: "none",
            x: relX,
            y: relY
        });

    }

}