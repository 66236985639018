
class FloatingIllustration{

    constructor() {

        let illustrations = document.querySelectorAll(".floating-illustration");
        let mm = gsap.matchMedia();

        illustrations.forEach( (element) => {

            let trigger = element.closest('.floating-illustration-trigger');
            let image = element.querySelector('.floating-illustration-animation');

            mm.add("(min-width: 992px)", () => {

                gsap.to(element, {
                    yPercent: -50,
                    opacity: 1,
                    ease: "power2.out",
                    duration: 1.5,
                    scrollTrigger: {
                        trigger: trigger,
                        start: "top bottom"
                    }
                });

                gsap.to(image, {
                    ease: "none",
                    yPercent : -15,
                    rotate: "-10deg",
                    scrollTrigger: {
                        trigger: image,
                        start: "top bottom",
                        end: "bottom top",
                        scrub: 1
                    }
                });

            });

        });

    }


}