
class PageContact{

    constructor() {

        let iframes = document.querySelectorAll('.lg-iframe');

        iframes.forEach( (iframe) => {

            lightGallery(iframe, {
                selector: 'this',
                speed: 800,
                licenseKey: '0000-0000-000-0001',
                thumbnail: false,
                counter: false,
                download: false,
                zoom: false,
                scale: 1,
                loop: true,
                fullScreen: false,
                autoplay: false,
                autoplayControls: false,
                allowMediaOverlap: true
            });

            iframe.addEventListener('lgBeforeOpen',function(event){
                smoother.paused(true);
            });
            iframe.addEventListener('lgBeforeClose',function(event){
                smoother.paused(false);
            });

        });


        let mapContainer = document.querySelector('.map');
        let mapMarker = document.querySelector('.map-marker');
        if( !mapContainer )
            return;

        let mapCreate = new MapMaker();
        let map = mapCreate.createDynamicMap(mapContainer);
        mapCreate.createMarker(mapMarker, map);
        map.setCenter(map.markers[0].getPosition());

    }

}