class Forms {
	constructor() {
		this.forms = document.querySelectorAll(".wpcf7");

		if (this.forms) {
			this.initInputEvents();
			this.initFormEvents();
		}
	}

	initFormEvents() {
		this.forms.forEach(wpcf7Form => {
			this.submitEvents(wpcf7Form);
			this.wpcf7FailEvents(wpcf7Form);
			this.wpcf7SuccessEvents(wpcf7Form);
		});
	}

	initInputEvents() {
		const inputs = document.querySelectorAll(".form-field:not(.type-checkbox):not(.type-radio):not(.type-file) input, .form-field textarea, .form-field select");
		inputs.forEach(input => {
			const inputContainer = input.closest(".form-field");

			input.addEventListener("focus", () => {
				inputContainer.classList.add("active");
			});

			input.addEventListener("blur", () => {
				inputContainer.classList.remove("active");

				if (input.value === "") {
					inputContainer.classList.remove("filled");
				} else {
					inputContainer.classList.add("filled");
				}
			});
		});

		// const submits = document.querySelectorAll(".form-actions button[type='submit']");
		// submits.forEach(submit => {
		// 	submit.insertAdjacentHTML("beforeend",
		// 		`<svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
		// 			<path d="M14.5 1C7.04416 1 1 7.04416 1 14.5C1 21.9558 7.04416 28 14.5 28C21.9558 28 28 21.9558 28 14.5C28 10.8679 26.5657 7.5709 24.2326 5.14437" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
		// 		</svg>`);
		// });

		// Managing input files
		const inputsFile = document.querySelectorAll(".form-field input[type='file']");
		inputsFile.forEach(input => {
			const inputContainer = input.closest(".form-field");
			inputContainer.insertAdjacentHTML("beforeend",
				`<span class="filename"></span>
				<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
					<path d="M29.3789 2.62656C27.7528 0.98805 25.5424 0.066925 23.2365 0.066925C20.9305 0.066925 18.7201 0.98805 17.094 2.62656L15.4986 4.30536C15.1467 4.71685 15.0271 5.27893 15.1805 5.79897C15.3337 6.3186 15.7393 6.72512 16.2578 6.87852C16.7763 7.03234 17.3372 6.91206 17.7482 6.55981L19.3597 4.86493C20.4078 3.8603 21.802 3.29961 23.2525 3.29961C24.7026 3.29961 26.0972 3.86027 27.1453 4.86493C28.1769 5.8967 28.7567 7.29748 28.7567 8.7582C28.7567 10.2189 28.177 11.6197 27.1453 12.6515L12.5948 27.2486C11.5468 28.2532 10.1525 28.8139 8.70205 28.8139C7.25193 28.8139 5.85733 28.2532 4.80926 27.2486C3.77761 26.2168 3.19778 24.816 3.19778 23.3553C3.19778 21.8946 3.77755 20.4938 4.80926 19.462L6.48448 17.7672L12.2602 11.9952V11.9956C12.6056 11.6576 13.0693 11.4684 13.5522 11.4684C14.0351 11.4684 14.4992 11.6576 14.8446 11.9956C15.1808 12.3392 15.3692 12.8014 15.3692 13.2825C15.3692 13.7639 15.1808 14.2261 14.8446 14.5698L9.06891 20.3579L9.06927 20.3575C8.76727 20.6576 8.5974 21.0656 8.59705 21.4913C8.59669 21.9174 8.76621 22.3257 9.06713 22.6262C9.36841 22.9267 9.77689 23.0941 10.2017 23.0916C10.6284 23.0898 11.0361 22.9171 11.3346 22.612L17.0942 16.84C18.0343 15.896 18.5625 14.6166 18.5625 13.2825C18.5625 11.9488 18.0343 10.6693 17.0942 9.72499C16.1384 8.80925 14.8666 8.29817 13.5443 8.29817C12.222 8.29817 10.9503 8.80923 9.9944 9.72499L4.25081 15.497L2.57559 17.0958C0.381176 19.2949 -0.476076 22.5005 0.327388 25.5044C1.13045 28.5087 3.47196 30.8552 6.46984 31.66C9.46735 32.4652 12.6661 31.6061 14.8605 29.407L29.379 14.8574C31.014 13.2279 31.9332 11.0127 31.9332 8.70181C31.9332 6.39096 31.014 4.17576 29.379 2.54621L29.3789 2.62656Z" fill="currentColor" />
				</svg>`);

			const responseContainer = inputContainer.querySelector(".filename");

			inputContainer.addEventListener("click", () => {
				input.click();
			});

			input.addEventListener("change", (event) => {
				let fileName = event.target.value.split('\\').pop();

				if (fileName) {
					responseContainer.innerHTML = fileName;
					inputContainer.classList.add("filled");
				} else {
					responseContainer.innerHTML = "";
					inputContainer.classList.remove("filled");
				}
			});

			// Firefox bug fix
			input.addEventListener("focus", () => {
				inputContainer.classList.add("active");
				input.classList.add("has-focus");
			});

			input.addEventListener("blur", () => {
				inputContainer.classList.remove("active");
				input.classList.remove("has-focus");
			});
		});
	}

	submitEvents(form) {
		const submitBtn = form.querySelector(".wpcf7 .form-actions button[type='submit']");

		if (submitBtn) {
			submitBtn.addEventListener("click", () => {
				form.classList.add("loading");
			});
		}
	}

	wpcf7FailEvents(form) {
		form.addMultipleEventListeners('wpcf7invalid wpcf7spam wpcf7mailfailed wpcf7unaccepted', () => {
			form.classList.remove("loading");
		});
	}

	wpcf7SuccessEvents(form) {
		form.addEventListener("wpcf7mailsent", () => {
			form.classList.remove("loading");
			form.classList.add("success");
			this.resetForm(form);
		});
	}

	resetForm(form) {
		setTimeout(function () {
			const successMessage = form.querySelector(".form-success");
			const inputs = form.querySelectorAll(".input-container");
			const resetBtn = form.querySelector("input[type='reset']");

			form.classList.remove("success");
			inputs.forEach(input => {
				input.classList.remove("filled");
			});
			resetBtn.click();
			successMessage.remove();
		}, 5000);
	}
}
