class Footer{

    constructor() {

        gsap.set('.footer-container', {
            yPercent: 50
        });
        const uncover = gsap.timeline({ paused:true });
        uncover.to('.footer-container', {
            yPercent: 0,
            ease: 'none'
        });

        ScrollTrigger.create({
            trigger: ".footer",
            pin: true,
            start: "bottom bottom",
            animation: uncover,
            scrub: true,
            end: "+=100%"
        });

    }

}