
class PagePartenaire{

    constructor() {

        let blog = document.querySelector('.partner-container');
        if( !blog )
            return;

        let mixer = mixitup(blog, {
            selectors: {
                target: '.blog-card'
            },
            callbacks: {
                onMixEnd: (state) => {
                    let selector = state.activeFilter.selector;
                    if( selector && selector !== ".blog-card" ){
                        window.location.hash = selector.replace(".","#");
                    } else {
                        window.location.hash = "";
                    }
                }
            },
            animation: {
                duration: 400,
                effects: 'fade translateZ(-360px) stagger(34ms)',
                easing: 'ease'
            }
        });
        let hash = window.location.hash;
        hash = hash.replace("#",".");
        if(hash){
            mixer.filter(hash);
        }

    }

}