
class PageStudio{

    constructor() {

        let gallery = document.querySelector('.studio-img-list');
        if( !gallery )
            return;

        lightGallery(gallery, {
            selector: '.img-gallery',
            speed: 800,
            licenseKey: '0000-0000-000-0001',
            thumbnail: false,
            counter: false,
            download: false,
            zoom: false,
            scale: 1,
            loop: true,
            fullScreen: false,
            autoplay: false,
            autoplayControls: false,
            allowMediaOverlap: true
        });

    }

}