
class SplitLine{

    // constructor() {
    //
    //     let splitLine = document.querySelectorAll('.title-level-1');
    //     splitLine.forEach( (elem) => {
    //         const wordSplit = new SplitText(elem, {
    //             type: "lines,words",
    //             linesClass: "splited-lines",
    //             wordsClass: "splited-word"
    //         });
    //     });
    //
    // }

}