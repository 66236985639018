
class PageAccount{

    constructor() {

        $("#FormAccount").submit(function(){

            var data = $(this).serialize() + '&act=envoi';
            var url  = $(this).attr('action');
            var $error = $(this).find('.error');

            $error.hide();

            $.ajax({
                type: "POST",
                data : data,
                url: url,
                success: function(retour) {
                    if(retour === '1') {
                        window.location.href = '/account';
                    } else {
                        $error.show();
                    }
                }
            });
            return false;
        });

    }

}