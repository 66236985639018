
class PageBlog{

    constructor() {

        this.blog = document.querySelector('.blog-container');
        if( this.blog )
            this.buildMix();

        $("#FormComment").submit(function(){
            if($("#FormComment").validationEngine('validate')){
                var data    = $(this).serialize() + '&act=envoi';
                var nb      = parseInt($(".comment--nb span").html())+1;
                var url     = $(this).attr('action');
                var $button = $(this).find('button');
                var $form = $(this);

                $button.find('span').html('Patientez...');

                $.ajax({
                    type: "POST",
                    data : data,
                    url: url,
                    success: function(retour) {
                        $(".comment--nb span").html(nb);
                        $("#comment-list").prepend(retour);
                        $button.find('span').html('Ajouter ce commentaire');
                        $form.find('input, textarea').each(function() {
                            $(this).val('');
                        });
                    }
                });
            }
            return false;
        });

    }

    buildMix(){
        let mixer = mixitup(this.blog, {
            selectors: {
                target: '.blog-card'
            },
            callbacks: {
                onMixEnd: (state) => {
                    let selector = state.activeFilter.selector;
                    if( selector && selector !== ".blog-card" ){
                        window.location.hash = selector.replace(".","#");
                    } else {
                        window.location.hash = "";
                    }
                }
            },
            animation: {
                duration: 400,
                effects: 'fade translateZ(-360px) stagger(34ms)',
                easing: 'ease'
            }
        });
        let hash = window.location.hash;
        hash = hash.replace("#",".");
        if(hash){
            mixer.filter(hash);
        }
    }

}