class Header{

    constructor() {

        let header = document.querySelector('.header-commun-inner');
        if( !header )
            return;

        gsap.to(header, {
            ease: "none",
            scale : 1.2,
            scrollTrigger: {
                trigger: header,
                start: "top top",
                end: "bottom top",
                scrub: 1,
            }
        });

    }

}