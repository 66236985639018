class StarterKitMcube {
	constructor() {
		this.madeByMcube();
		this.mcubeDebug();
		mobileCheck = new MobileCheck();
		new SmoothScroll();
		new ResizeEvents();
		new Parallax();
		new AppearOnScroll();
		pageTransition = new CustomPageTransition();
		new BasePageTransition();
		new DataLink();
		new Forms();
		mapMaker = new MapMaker();
		new LoadScript();
		new TextAppear();
	}

	consoleLog(message, bg, color, link) {
		console.log('%c' + message, 'background-color:' + bg + '; color:' + color + ';padding:3px 5px;', link);
	}

	madeByMcube() {
		this.consoleLog('Fabriqué à Lyon par Mcube', '#fa2165', '#ffffff', 'www.mcube.fr');
	}

	mcubeDebug() {
		devMode = localStorage.getItem('devMode');
		if (devMode === 'true') {
			document.body.classList.add('debug-mode');
		}
	}
}
