class PageQuestionnaire {

    constructor() {


        let form = document.querySelector('#form-questionnaire');

        if (form) {
            let checkBox = form.querySelectorAll('[type="checkbox"], [type="radio"]');
            checkBox.forEach((box) => {

                let field = box.closest('.field-item');
                let input = field.querySelector('.other');

                if( !input )
                    return;

                if (box.value == 'Autre') {
                    field.classList.add('field-item--other');
                }

                box.addEventListener("change", () => {
                    if (box.value == 'Autre' && box.checked === true) {
                        input.style.display = 'block';
                    } else {
                        input.style.display = 'none';
                    }
                });

            });

            $('form').on( "submit", function(e){

                if ($(form).validationEngine('validate')) {

                    let data = $( this ).serialize() + '&act=envoi';
                    let url = $(form).attr('action');
                    let error = $(form).find('.error');
                    let retour = document.querySelector('.form-retour');
                    let submit = $(form).find('[type="submit"]');

                    error.hide();

                    submit.prop("disabled", true);
                    submit.html("Envoi en cours...");

                    let close = document.querySelector(".form-retour-close");
                    close.addEventListener('click', () => {
                        document.body.classList.remove("form-success");
                        submit.prop("disabled", false);
                        submit.html("Envoyer");
                    });

                    $.ajax({
                        type: "POST",
                        data: data,
                        dataType: 'json',
                        url: url,
                        success: function (response) {
                            document.body.classList.add("form-success");
                            retour.querySelector('.form-retour-inner').innerHTML = response.message;
                        }
                    });
                } else {

                    let li = form.querySelector('.formError:first-of-type').closest('.collapse:not(.open)');
                    if (li) {
                        li.querySelector('.collapse-head').click();
                    }

                }
                e.preventDefault();
                return false;

            });
        }


    }

}