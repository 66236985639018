let devMode,
	mobileCheck,
	pageTransition,
	pageTransitionEnabled = true,
	resizeCallbacks = [],
	mapMaker,
	mobileResizeCallbacks = [],
	smoother;

document.addEventListener("DOMContentLoaded", function () {
	new StarterKitMcube();
	// Composants Mobile & Desktop

    new AnimationBackground();
    new AppearBlock();
    new Footer();
    new Header();

    new ButtonFollower();
    new Collapse();
    new CollapseQuestion();
    new Navigation();
    new SplitWord();
    new SplitLine();
    new FloatingIllustration();

    new PageAccount();
    new PageBlog();
    new PageContact();
    new PageFaq();
    new PageGallery();
    new PageHome();
    new PageInformation();
    new PageNews();
    new PagePartenaire();
    new PagePresse();
    new PagePrestation();
    new PageQuestionnaire();
    new PageStudio();
    new PageSuivi();
    new PageTeam();
    new PageTestimony();

	if (mobileCheck.isMobile) {
		// Composants Mobile uniquement
	} else {

	}
});
