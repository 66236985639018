class PageSuivi {
    constructor() {
        this.suiviPage = document.querySelector("body.suivi");
        if( !this.suiviPage ){
            return;
        }

        this.suiviIllustration = this.suiviPage.querySelector(".inner-image");

        if (this.suiviIllustration && window.innerWidth > 767) {
            this.rotateImage();
        }
    }

    rotateImage() {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: this.suiviIllustration,
                start: "top bottom",
                end: "bottom top",
                scrub: 1,
            }
        });

        tl.to(this.suiviIllustration, {
            rotate: "-10deg",
            yPercent: -15,
            ease: "none",
        });
    }
}

