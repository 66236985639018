class BasePageTransition{
	constructor() {
		if (pageTransitionEnabled) {
			imagesLoaded(document.body,
				{
					background: true
				},
				() => {
					pageTransition.enter(function () {
						window.dispatchEvent(new Event('transitionDone'));
					});
			});
		} else {
			window.dispatchEvent(new Event('transitionDone'));
		}
	}
}

