
class SplitWord{

    constructor() {

        let splitWord = document.querySelectorAll('.split-word');
        splitWord.forEach( (elem) => {
            const wordSplit = new SplitText(elem, {
                type: "words",
                wordsClass: "splited-word"
            });
        });

    }

}