
class AnimationBackground{

    constructor() {

        let panes = document.querySelectorAll('.animation-background .pane');

        ScrollTrigger.create({
            trigger: document.body,
            pin: ".animation-background",
            pinSpacer: false,
            pinSpacing: false,
            start: 0,
            scrub: true,
            endTrigger: ".page-content",
            end: "bottom bottom"
        });

        let tl = gsap.timeline({
            repeat : -1,
            repeatDelay: 1,
            yoyo : true
        });
        panes.forEach( (pane) => {
            tl.to(pane, {
                opacity : 1,
                ease : "linear",
                duration : 2
            });
        });

    }

}