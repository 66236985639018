class PageTeam{

    constructor() {

        let teams = document.querySelectorAll('.team-member');

        teams.forEach( (team) => {

            team.addEventListener('mouseover', () => {

                this.tl = gsap.timeline();

                this.tl.to(team, {
                    backgroundPosition: '0px 20%',
                    duration: 0
                });
                this.tl.to(team, {
                    backgroundPosition: '0px 40%',
                    duration: 0
                }, 0.3);
                this.tl.to(team, {
                    backgroundPosition: '0px 60%',
                    duration: 0
                }, 0.6);
                this.tl.to(team, {
                    backgroundPosition: '0px 80%',
                    duration: 0
                }, 0.9);
                this.tl.to(team, {
                    backgroundPosition: '0px 100%',
                    duration: 0
                }, 1.2);
                this.tl.to(team, {
                    backgroundPosition: '0px 80%',
                    duration: 0
                }, 1.5);
                this.tl.to(team, {
                    backgroundPosition: '0px 60%',
                    duration: 0
                }, 1.8);
                this.tl.to(team, {
                    backgroundPosition: '0px 40%',
                    duration: 0
                }, 2.1);
                this.tl.to(team, {
                    backgroundPosition: '0px 20%',
                    duration: 0
                }, 2.4);
                this.tl.to(team, {
                    backgroundPosition: '0px 0',
                    duration: 0
                }, 2.7);

            });

            team.addEventListener('mouseleave', () => {
                this.tl.kill();
                gsap.set(team, {
                    backgroundPosition: '0 0',
                    duration: 0
                });
            });

        });

    }

}