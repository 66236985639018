class PageFaq{

    constructor() {

        let categories = document.querySelectorAll('.faq-category');
        let answers = document.querySelectorAll('.faq-answers');
        let header = document.querySelector(".header-desktop .nav-main");

        let faqCategories = document.querySelector('.faq-categories');
        if( faqCategories ) {
            ScrollTrigger.create({
                trigger: faqCategories,
                start: `top ${header.offsetHeight}`,
                pin: true,
                endTrigger: ".section-faq",
                end: "bottom bottom"
            });
        }

        categories.forEach( (category) => {

            category.addEventListener('click', () => {
                let id = category.dataset.id;
                smoother.scrollTo( `#${id}`, true, `top ${header.offsetHeight}` );
            });

        });

        answers.forEach( (answer) => {

            let id = answer.getAttribute('id');
            let target = document.querySelector(`.faq-category[data-id=${id}]`);
            ScrollTrigger.create({
                trigger: answer,
                start: `top center`,
                end: `bottom center`,
                toggleClass: 'active',
                onEnter() {
                    target.classList.add('active');
                },
                onLeave() {
                    target.classList.remove('active');
                },
                onEnterBack() {
                    target.classList.add('active');
                },
                onLeaveBack() {
                    target.classList.remove('active');
                }
            });

        });


    }

}