class LoadScript {
    static load(script, callback) {
        const foundScript = document.querySelector("script[src='" + script + "']");

        if (foundScript) {
            callback();
        } else {
            const loadingScript = document.createElement('script');
            loadingScript.setAttribute('src', script);
            loadingScript.setAttribute('data-loadscript', 'true');
            loadingScript.async = true;
            document.body.appendChild(loadingScript);

            loadingScript.addEventListener('load', function () {
                callback();
            });
        }
    }
}
